import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'; // NOSONAR
import { quickValidate } from '../api/validate';
import useSearchParams from '../hooks/useSearchParams';
import Loading from './common/Loading';
import Redirect from './common/Redirect';
import { concatUrlParams } from '../util/Util';
import MSG from '../util/Messages';

/**
 * form element schema++++++++++++++++++
 *
 * @typedef {Object} QuickValidationForm~Schema
 * @property {string} ahpra_number
 * @property {string} access_code
 * @property {string} client_id
 * @property {string} redirect_uri
 * @property {string} state
 * @property {string} reference
 */
const schema = yup.object().shape({
  ahpra_number: yup.string().trim(),
  access_code: yup.string().trim(),
  client_id: yup.string().trim().required(MSG.apiIdRequire),
  redirect_uri: yup.string().trim().required(MSG.callbackRequire),
  state: yup.string().trim().required(MSG.stateRequire),
  reference: yup.string().trim(),
});

const ValidationCollectionStatement = () => (
  <div className="form-group hint">
    COLLECTION STATEMENT: We collect your personal information to process your
    access to this website. Without this information we are unable to provide
    you with access to this website. We do not store or hold your information
    for any other purpose. For further information about how you can request
    access to or correct your personal information, make a privacy enquiry or
    complaint privacy practices, or to see our full Privacy Policy please go
    to&nbsp;
    <a
      className="break-all"
      href="https://www.jnjmedtech.com/en-AU/policies-privacy"
      target="_blank"
      rel="noreferrer"
    >
      www.jnjmedtech.com/en-AU/policies-privacy
    </a>
  </div>
);

const SubmittingStatement = () => (
  <div className="form-group hint">
    By clicking submit, you certify that the AHPRA registration number provided
    is your own, or the access code has been provided to you personally by
    Johnson & Johnson MedTech.
  </div>
);

const RegisterGuide = ({ searchStr }) => (
  <div className="form-group hint">
    If you do not have an AHPRA Registration number or Access Code, please click
    <Link to={searchStr ? `/register?${searchStr}` : '/register'}> here</Link>
    <span>.</span>
  </div>
);

RegisterGuide.propTypes = {
  searchStr: PropTypes.string,
};

RegisterGuide.defaultProps = {
  searchStr: '',
};

const QuickValidationForm = () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // set QuickValidationForm component states
  const [canSubmit, setCanSubmit] = useState(true);
  const [responseErr, setResponseErr] = useState('');
  // set loading status during API request
  const [loading, setLoading] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [callbackUrl, setCallbackUrl] = useState('');
  const [callbackState, setCallbackState] = useState('');
  const [callbackReference, setCallbackReference] = useState('');

  // set form common error message
  useEffect(() => {
    if (Object.keys(errors).length) {
      const errorKey = Object.keys(errors)[0];
      setResponseErr(errors[errorKey]?.message);
    }
  }, [errors]);

  /**
   * quick validation form submit
   *
   * @param {Object} data form submit datas
   * @return prevent form submit if form datas are invalidate
   */
  const onsubmit = async (data) => {
    const { ahpra_number: ahpraNumber, access_code: formAccessCode } = data;

    if (!ahpraNumber && !formAccessCode) {
      // prevent form submit
      // if both ahpraNumber and formAccessCode are empty.
      setResponseErr(MSG.validateCodeRequire);
      return;
    }

    try {
      setCanSubmit(false);
      setLoading(true);

      // API to validate code
      const { data: res } = await quickValidate(data);
      const { code, message } = res;

      if (code !== 200) {
        setResponseErr(message);
      } else {
        const {
          code: AccessCode,
          redirect_uri: CallbackUrl,
          state: stateStr,
          reference: referenceStr,
        } = res?.data;

        setAccessCode(AccessCode);
        setCallbackUrl(CallbackUrl);
        setCallbackState(stateStr);
        setCallbackReference(referenceStr);
      }
    } catch (err) {
      window.console.log(err);
      setResponseErr(MSG.networkError);
    } finally {
      // reset page state
      setCanSubmit(true);
      setLoading(false);
    }
  };

  /**
   * clear form error msg when form datas change
   *
   */
  const handleChange = () => {
    setResponseErr('');
  };

  // get search params from url
  const clientId = useSearchParams('client_id');
  const redirectUri = useSearchParams('redirect_uri');
  const reference = useSearchParams('reference');
  const state = useSearchParams('state');

  // concat register url
  const searchStr = concatUrlParams({
    reference,
    client_id: clientId,
    redirect_uri: redirectUri,
  });

  // default form control class
  const formItemClass = 'form-control';

  const HiddenFields = ({ hiddenFields }) =>
    hiddenFields.map(({ name, defaultValue }) => (
      <div key={name} className="form-group">
        <input
          type="text"
          hidden
          name={name}
          defaultValue={defaultValue}
          ref={register}
        />
      </div>
    ));

  return (
    <div className="container from-container">
      <Loading isShow={loading} />
      {accessCode && callbackUrl ? (
        <Redirect
          accessCode={accessCode}
          callbackUrl={callbackUrl}
          state={callbackState}
          reference={callbackReference}
        />
      ) : (
        <form
          onSubmit={handleSubmit(onsubmit)}
          onChange={handleChange}
          className="quick-validation-form"
        >
          <div className="form-group hint">
            The webpage you are trying to view is intended for Healthcare
            Professionals from Australia only. Healthcare Professionals, please
            complete the required field to proceed. If you don’t know your AHPRA
            (or registration) number, you can find it by inputting your name,
            specialty and postcode
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.ahpra.gov.au/Registration/Registers-of-Practitioners.aspx"
            >
            &nbsp;here.
            </a>
          </div>
          <div className="form-group">
            <div className="link-wrapper">
              <a
                title="TGA Link for the requirement"
                rel="noreferrer"
                target="_blank"
                href="https://www.tga.gov.au/advertising-health-professionals"
              >
                TGA Link for the requirement
              </a>
            </div>
            <input
              className={classNames(formItemClass, {
                'is-invalid': errors.ahpra_number,
              })}
              ref={register}
              id="ahpra-number"
              name="ahpra_number"
              type="text"
              placeholder="Please enter your AHPRA registration number
                to view this webpage"
            />
          </div>
          <div className="form-group font14">
            Please enter your AHPRA registration number in full comprising of 3
            letters and 10 numbers, or
          </div>
          <div className="form-group">
            <input
              className={classNames(formItemClass, {
                'is-invalid': errors.access_code,
              })}
              ref={register}
              id="access-code"
              name="access_code"
              type="text"
              placeholder="Please enter the personalised access code
                provided to you by J&J MedTech."
            />
          </div>
          <HiddenFields
            hiddenFields={[
              { name: 'client_id', defaultValue: clientId },
              { name: 'redirect_uri', defaultValue: redirectUri },
              { name: 'state', defaultValue: state },
              { name: 'reference', defaultValue: reference },
              // eslint-disable-next-line comma-dangle
              { name: '_home_address' },
            ]}
          />
          <RegisterGuide searchStr={searchStr} />
          <ValidationCollectionStatement />
          <SubmittingStatement />
          {responseErr && (
            <div className="form-group form-msg text-red">{responseErr}</div>
          )}
          <button
            type="submit"
            className="btn btn-jj btn-jj-red"
            disabled={!canSubmit}
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default QuickValidationForm;
