import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'; // NOSONAR
import { registerRequest } from '../api/register';
import useSearchParams from '../hooks/useSearchParams';
import Loading from './common/Loading';
import MSG from '../util/Messages';

/**
 * form element schema
 *
 * @typedef {Object} RegisterForm~Schema
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 * @property {string} institutions
 * @property {string} client_id
 * @property {string} redirect_uri
 */
const schema = yup.object().shape({
  first_name: yup.string().required(MSG.firstNameRequire),
  last_name: yup.string().required(MSG.lastNameRequire),
  email: yup.string().email(MSG.emailValid).required(MSG.emailRequire),
  institutions: yup.string().required(MSG.institutionsRequire),
  client_id: yup.string().required(MSG.apiIdRequire),
  redirect_uri: yup.string(),
  reference: yup.string().trim(),
});

/**
 * Form statement component.
 * @returns React.FC
 */
const FormStatement = () => (
  <p className="lead">
    Access to this webpage is limited in accordance with our obligations under
    the Therapeutic Goods Act 1989 (Cth). Only health care professionals and
    certain persons engaged in the purchase of items on behalf of hospitals are
    allowed access. Please complete the below and we will be in touch to provide
    an access code where appropriate.
  </p>
);

/**
 * Form collection statement component.
 * @returns React.FC
 */
const CollectionStatement = () => (
  <div className="form-group hint">
    COLLECTION STATEMENT: We collect your personal information to process your
    access request for this website and for our related administrative purposes.
    Without this information we are unable to process your access request.
    Subject to some exceptions permitted by law you are able to request access
    to, or correction of, your personal information. For further information
    about how you can request access to or correct your personal information,
    make a privacy enquiry or complaint privacy practices, or to see our full
    Privacy Policy please go to&nbsp;
    <a
      className="break-all"
      target="_blank"
      rel="noreferrer"
      href="https://www.jnjmedtech.com/en-AU/policies-privacy"
    >
      www.jnjmedtech.com/en-AU/policies-privacy
    </a>
    <p className="mt10">
      By clicking submit, you certify that the information provided above is
      your own.
    </p>
  </div>
);

const RegisterForm = () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // set RegisterForm component states
  const [canSubmit, setCanSubmit] = useState(true);
  const [responseErr, setResponseErr] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  // set loading status during API request
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // set client_id valid error message
    if (errors?.client_id?.message) {
      setResponseErr(errors?.client_id?.message);
    }
  }, [errors]);

  /**
   * register validation form submit
   *
   * @param {Object} data form submit datas
   */
  const onSubmit = async (data) => {
    try {
      setCanSubmit(false);
      setLoading(true);
      // API to get register info
      const { data: res } = await registerRequest(data);
      const { code, message } = res;
      if (code !== 200) {
        setResponseErr(message);
      } else {
        setSuccessMsg(MSG.registerSuccess);
      }
    } catch (err) {
      window.console.log(err);
      setResponseErr(MSG.networkError);
    } finally {
      // reset page state
      setCanSubmit(true);
      setLoading(false);
    }
  };

  /**
   * clear form error msg when form datas change
   */
  const handleChange = () => {
    setSuccessMsg('');
    setResponseErr('');
  };

  function goHistory() {
    window.history.go(-1);
  }

  // default form control class
  const formItemClass = 'form-control';

  return (
    <div className="container from-container">
      <Loading isShow={loading} />
      <Button className="back-link" variant="link" onClick={() => goHistory()}>
        &lt;&lt; Back
      </Button>
      <FormStatement />
      <form
        className="register-form"
        onSubmit={handleSubmit(onSubmit)}
        onChange={handleChange}
      >
        <div className="form-group form-group-require">
          <input
            className={classNames(formItemClass, {
              'is-invalid': errors.first_name,
            })}
            id="first-name"
            type="text"
            ref={register}
            name="first_name"
            placeholder="First Name"
          />
          <div className="invalid-feedback">{errors?.first_name?.message}</div>
        </div>
        <div className="form-group form-group-require">
          <input
            className={classNames(formItemClass, {
              'is-invalid': errors.last_name,
            })}
            id="last-name"
            type="text"
            ref={register}
            name="last_name"
            placeholder="Last Name"
          />
          <div className="invalid-feedback">{errors?.last_name?.message}</div>
        </div>
        <div className="form-group form-group-require">
          <input
            className={classNames(formItemClass, {
              'is-invalid': errors.email,
            })}
            id="email"
            type="text"
            ref={register}
            name="email"
            placeholder="Email"
          />
          <span className="invalid-feedback">{errors?.email?.message}</span>
        </div>
        <div className="form-group form-group-require">
          <input
            className={classNames(formItemClass, {
              'is-invalid': errors.institutions,
            })}
            id="medical-instructions"
            type="text"
            ref={register}
            name="institutions"
            placeholder="Medical Institution"
          />
          <div className="invalid-feedback">
            {errors?.institutions?.message}
          </div>
        </div>
        <CollectionStatement />
        <div className="form-group">
          <input
            type="text"
            hidden
            name="client_id"
            ref={register}
            defaultValue={useSearchParams('client_id')}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            hidden
            name="reference"
            ref={register}
            defaultValue={useSearchParams('reference')}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            hidden
            name="redirect_uri"
            ref={register}
            defaultValue={useSearchParams('redirect_uri')}
          />
        </div>
        <div className="form-group">
          <input
            className="hidden"
            type="text"
            name="_home_address"
            ref={register}
          />
        </div>
        {responseErr && (
          <div className="form-group form-msg text-red">{responseErr}</div>
        )}
        {successMsg && (
          <div className="form-group form-msg text-success">{successMsg}</div>
        )}
        <button
          type="submit"
          className="btn btn-jj btn-jj-red"
          disabled={!canSubmit}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;
