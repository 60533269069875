import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { concatUrlParams } from '../../util/Util';

/**
 * redirect to callback page after 5 seconds count down
 *
 * @param {string} callbackUrl redirect url
 * @param {string} accessCode search code in url
 * @param {string} state search code in url
 * @param {string} reference search code in url
 * @return Component JSX
 */
const Redirect = ({ callbackUrl, accessCode, state, reference }) => {
  const [seconds, setSecond] = useState(4);
  const searchStr = concatUrlParams({ code: accessCode, state, reference });

  // timer cut down
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => {
        setSecond(seconds - 1);
      }, 100);
    } else if (callbackUrl.indexOf('?') > -1) {
      window.location = `${callbackUrl}&${searchStr}`;
    } else {
      window.location = `${callbackUrl}?${searchStr}`;
    }
  }, [seconds, callbackUrl, searchStr]);

  return (
    <div className="valid-success">
      <p>
        Verification successful, redirecting you now...
      </p>
    </div>
  );
};

// default propTypes for Redirect
Redirect.defaultProps = {
  callbackUrl: '',
  accessCode: '',
  state: '',
  reference: '',
};

// set default propTypes for Redirect params
Redirect.propTypes = {
  callbackUrl: PropTypes.string,
  accessCode: PropTypes.string,
  state: PropTypes.string,
  reference: PropTypes.string,
};

export default React.memo(Redirect);
