import { useLocation } from 'react-router-dom';

/**
 * get serach param value by the key
 *
 * @param {string} id serach param key
 */
const useSearchParams = (id) =>
  new window.URLSearchParams(useLocation().search).get(id) || '';

export default useSearchParams;
