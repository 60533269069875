export default {
  // validation form
  apiIdRequire: 'ClientID is a required field',
  callbackRequire: 'Redirect Uri is a required field',
  stateRequire: 'State is a required field',
  validateCodeRequire: 'Please key in your AHPRA code or Access code.',

  // register form
  firstNameRequire: 'First Name is a required field',
  lastNameRequire: 'Last Name is a required field',
  emailRequire: 'Email is a required field',
  emailValid: 'Email must be a valid email',
  institutionsRequire: 'Institution is a required field',
  registerSuccess:
    'Your request has been submitted successfully! You will hear from us soon.',

  // Network Error
  networkError: 'Internet error.',
};
