import React from 'react';

/**
 * Component of Page not found
 *
 */
const PageNotFound = () => (
  <div className="container page-not-found">
    <h1>Something Went Wrong</h1>
    <p>We couldn&apos;t find the page you are looking for (404).</p>
  </div>
);

export default PageNotFound;
