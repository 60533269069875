// eslint-disable-next-line import/prefer-default-export
export const baseUrl = ((env) => {
  switch (env) {
    // pro env
    case 'production':
      return 'https://uui498as1h.execute-api.ap-southeast-1.amazonaws.com/prod/v1/au';
    // stage env
    case 'stage':
      return 'https://wrnnc32qgi.execute-api.ap-southeast-1.amazonaws.com/stg/v1/au';
    // dev env
    default:
      return 'https://3bl3cav463.execute-api.ap-southeast-1.amazonaws.com/dev/v1/au';
  }
})(process.env.REACT_APP_ENV);
