import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import Header from './common/Header';
import Footer from './common/Footer';

/**
 * common wrapper structure for React pages
 *
 * @param {string} title
 * @param {Component} children React Component
 * @returns Component JSX
 */
const DocumentTitle = ({ title, children }) => {
  useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  return (
    <>
      <Container fluid>
        <Header />
        <Row className="page-main-wrapper">
          <div className="page-main">
            <div className="page-main-inner">{children}</div>
          </div>
        </Row>
        <Footer />
      </Container>
    </>
  );
};

// default propTypes for DocumentTitle
DocumentTitle.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default DocumentTitle;
