/**
 * concat search params for url
 *
 * @param {Object} search all url seach key-value
 * @return {string} seach params string
 */

// eslint-disable-next-line import/prefer-default-export
export const concatUrlParams = (search) => {
  const url = new window.URLSearchParams();
  if (search) {
    Object.keys(search).forEach((keyItem) => {
      if (search[keyItem]) {
        url.append(keyItem, search[keyItem]);
      }
    });
  }

  return url.toString();
};
