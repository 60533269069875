import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './styles/index.scss';

const googleAnalyticsCode = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-PCJFC1398Y';
  script.id = 'ga-script';
  if (!document.getElementById('ga-script')) {
    document.head.appendChild(script);
  }

  if (typeof window === 'undefined') {
    return;
  }
  const responseHtml =
    'window.dataLayer = window.dataLayer || [];\n' +
    'function gtag(){dataLayer.push(arguments);}\n' +
    "gtag('js', new Date());\ngtag('config', 'G-PCJFC1398Y');";
  const scriptDataLayer = document.createElement('script');
  scriptDataLayer.innerHTML = responseHtml;
  document.head.appendChild(scriptDataLayer);
};

ReactDOM.render(
  <React.StrictMode>
    <App />
    {googleAnalyticsCode()}
  </React.StrictMode>,
  document.getElementById('root')
);
