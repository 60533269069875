import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Loading = ({ isShow }) => (
  isShow ? (
    <div className="loading loading-fixed">
      <Spinner className="spinner-common" animation="border" role="status" />
    </div>
  ) : (
    ''
  )
);

Loading.defaultProps = {
  isShow: false,
};

Loading.propTypes = {
  isShow: PropTypes.bool,
};

export default Loading;
