import React from 'react';
import { Row } from 'react-bootstrap';
import FooterLogo from '../../assests/logo_jnj_white.svg';

const Footer = () => (
  <Row>
    <div className="footer">
      <div className="main-footer clearfix">
        {/* footer logo */}
        <div className="footer-logo fl">
          <img src={FooterLogo} alt="Johnson & Johnson" />
        </div>

        {/* social share */}
        <ul className="footer-menu social-links fl">
          <li>
            <a
              href="https://twitter.com/JNJMedDevices"
              target="_blank"
              rel="noopener noreferrer"
              className="twitter"
            >
              twitter
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/showcase/johnson-&-johnson-medical-devices-companies/"
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin"
            >
              linkedin
            </a>
          </li>
        </ul>

        {/* footer links */}
        <ul className="footer-menu footer-links fl">
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.jnjmedtech.com/en-AU/policies-privacy"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.jnjmedtech.com/en-AU/policies-legal"
            >
              Legal Notice
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.jnjmedtech.com/en-AU/trading-terms"
            >
              Trading Terms
            </a>
          </li>
        </ul>

        {/* copyright */}
        <div className="copyright fr">
          <p>
            All contents © MedTech Business Services, Inc. 2021. All Rights
            Reserved
          </p>
        </div>
      </div>

      {/* footer text */}
      <div className="tips">
        <p>
          Johnson &amp; Johnson Medical Pty Ltd. 1-5 Khartoum Road, North Ryde,
          NSW 2113, Australia. ABN 85 000 160 403.
          <br />
          This is intended for Healthcare Professionals from Australia only.
          <br />
          Last updated 17 December 2020. All rights reserved.
        </p>
      </div>
    </div>
  </Row>
);

export default Footer;
