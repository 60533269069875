import React from 'react';
import { Row } from 'react-bootstrap';
import logo from '../../assests/logo_jnj.svg';

const Header = () => (
  <Row>
    <div className="header">
      <div className="pre-text"> Healthcare Professional View </div>
      <div className="logo">
        <a
          href="https://www.jnjmedtech.com/en-AU"
          rel="noreferrer"
          target="_blank"
        >
          <img alt="Logo" src={logo} />
        </a>
      </div>
    </div>
  </Row>
);

export default Header;
