import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import QuickValidationForm from './components/QuickValidationForm';
import RegisterForm from './components/RegisterForm';
import DocumentTitle from './components/DocumentTitle';
import PageNotFound from './components/common/PageNotFound';

/**
 * Main App entery
 * Route to different page
 *
 * @returns Component JSX
 */
function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route exact path="/">
            <DocumentTitle title="Validate">
              <QuickValidationForm />
            </DocumentTitle>
          </Route>
          <Route exact path="/register">
            <DocumentTitle title="Register">
              <RegisterForm />
            </DocumentTitle>
          </Route>
          <Route path={['*', '/404']}>
            <DocumentTitle title="404">
              <PageNotFound />
            </DocumentTitle>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
